#root {
  position: relative;
}

.boxes {
  background: linear-gradient(to top, #f4f4f4 0%, #ffffff 100%);
  width: 100%;
  height: 100vh;
  position: fixed;
  font-size: 80px;
}
.single-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.single-box li {
  position: absolute;
  display: block;
  list-style: none;
  width: 25px;
  height: 25px;
  animation: animate 20s linear infinite;
  bottom: -150px;
}
.single-box li:nth-child(1) {
  left: 86%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}
.single-box li:nth-child(2) {
  left: 12%;
  width: 30px;
  height: 30px;
  animation-delay: 1.5s;
  animation-duration: 10s;
}
.single-box li:nth-child(3) {
  left: 29%;
  width: 100px;
  height: 100px;
  animation-delay: 5.5s;
}
.single-box li:nth-child(4) {
  left: 42%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 15s;
}
.single-box li:nth-child(5) {
  left: 65%;
  width: 40px;
  height: 40px;
  animation-delay: 0s;
}
.single-box li:nth-child(6) {
  left: 15%;
  width: 110px;
  height: 110px;
  animation-delay: 3.5s;
}
.single-box li:nth-child(7) {
  left: 75%;
  width: 200px;
  height: 200px;
  animation-delay: 4.5s;
  bottom: -250px;
}
@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0.18;
  }
  100% {
    transform: translateY(-800px) rotate(360deg);
    opacity: 0;
  }
}
